import { graphql, Link } from 'gatsby';
import React from 'react';

import * as styles from './index.module.scss';
import GradientButton from '../components/gradient-button';

const links = [
  { label: 'FNCS', path: '/fncs' },
  { label: 'Kinch Premium', path: 'https://premium.kinchanalytics.com', external: true },
  { label: 'About', path: '/about' },
  // { label: 'Blog', path: '#' },
  // { label: 'Stats', path: '#' },
]

const IndexPage = ({ data }) => {
  const announcement = data.allContentfulAnnouncement.nodes[0]

  function wrapAnnouncementCta(announcement, ctaElement) {
    const shouldWrapLink = announcement.ctaUrl && announcement.ctaUrl !== '#';

    const element = (
      <div className="max-w-3xl mt-16 flex flex-col items-start gap-y-2.5 sm:gap-y-5">
        <div className="mr-4 px-2 rounded-2xl border-2 border-white border-opacity-10 bg-indigo-900">
          <div
            className="text-transparent uppercase leading-5 font-medium text-xs
              bg-clip-text bg-gradient-to-l from-ka-cyan via-ka-blue to-ka-purple"
          >
            Announcement
          </div>
        </div>
        <div className="text-xl sm:text-3xl font-medium">{announcement.content.content}</div>
        {shouldWrapLink && ctaElement}
      </div>);

    return shouldWrapLink
      ? <a href={announcement.ctaUrl}>{element}</a>
      : <>{element}</>;
  };

  return (<>
    {announcement && wrapAnnouncementCta(announcement, <>
      <span className="text-xs uppercase text-ka-cyan font-medium sm:hidden">
        {announcement.ctaText}
      </span>
      <GradientButton className="h-10 hidden sm:block">
        <span className="px-5 text-xs">
          {announcement.ctaText}
        </span>
      </GradientButton>
    </>)}

    <div className={["mt-16 mb-8 w-full grid gap-4 sm:gap-5", styles.gridLinks].join(' ')}>
      {links.map((link, idx) => (
      <div className="
        first:h-32 h-40 first:col-span-2
        sm:first:h-40
        md:first:h-full md:h-48 md:first:row-span-2 md:first:col-span-1
        p-0.5 bg-gradient-to-b from-ka-cyan via-ka-blue to-ka-purple rounded-3xl"
        key={idx}
      >
        {link.external ? (
          // External Link - Uses <a>
          <a href={link.path} className={["h-full flex flex-col justify-between items-start rounded-3xl p-5 md:p-7 bg-indigo-900", styles[`bgHero${idx}`]].join(' ')}>
            <span className="w-6/12 text-2xl font-medium md:text-3xl md:font-bold">{link.label}</span>
            <span className="text-xs uppercase text-ka-cyan font-medium sm:hidden">
              View
            </span>
            <GradientButton className="h-10 hidden sm:block">
              <span className="px-5 text-xs">Improve your Game</span>
            </GradientButton>
          </a>
        ) : (
          // Internal Link - Uses <Link>
          <Link to={link.path}>
            <div className={["h-full flex flex-col justify-between items-start rounded-3xl p-5 md:p-7 bg-indigo-900", styles[`bgHero${idx}`]].join(' ')}>
              <span className="w-6/12 text-2xl font-medium md:text-3xl md:font-bold">{link.label}</span>
              <span className="text-xs uppercase text-ka-cyan font-medium sm:hidden">
                View
              </span>
              <GradientButton className="h-10 hidden sm:block">
                <span className="px-5 text-xs">View more</span>
              </GradientButton>
            </div>
          </Link>
        )}
        </div>
        ))}
    </div>
  </>)
};

export const query = graphql`
  query AnnouncementQuery {
    allContentfulAnnouncement(limit: 1, sort: {fields: updatedAt, order: DESC}) {
      nodes {
        content {
          content
        }
        ctaText
        ctaUrl
      }
    }
  }
`

export default IndexPage;
